import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

const initialState = {
  // data
  data: null,
  // loading
  loading: false,
  // error
  error: null,
  // filter
  filter: null,
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
      state.data = null;
      state.error = null;
      state.filter = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },

    // Profile Update Success
    getDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },

    // Reset Data
    resetData(state) {
      state.data = null;
      state.loading = false;
      state.error = null;
    },

    // set filter
    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export function getProject(projectId) {
  return async (dispatch) => {
    dispatch(actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/projects/${projectId}`);
      dispatch(actions.getDataSuccess(response.data));
    } catch (error) {
      dispatch(actions.hasError(error));
    }
  };
}
