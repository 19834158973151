export const AVAILABLE_MODELS = {
  keyword_extraction: 'keyword_extraction',
  sentiment_analysis: 'sentiment_analysis',
};

export const ML_MODEL_COVERS = {
  sentiment_analysis: '/assets/images/covers/sentiment-analysis.png',
  keyword_extraction: '/assets/images/covers/keyword-extraction.png',
};

export const WORKFLOW_COVER = '/assets/images/covers/workflow.png';
export const TEMPLATE_COVER = '/assets/images/covers/template.png';
export const AI_COVER = '/assets/images/covers/ai-models.png';
export const DATA_CLEANING_COVER =
  '/assets/images/covers/data-cleaning-module.png';

export const WORKFLOW_TEMPLATES = {
  keyword_sentiment: 'keyword_sentiment',
};
