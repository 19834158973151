import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices

import userReducer from './slices/user';
import sentimentAnalysisReducer from './slices/sentimentAnalysis';
import keywordExtractionReducer from './slices/keywordExtraction';
import mlModelsReducer from './slices/mlModels';
import workflowsReducer from './slices/workflows';
import workflowReducer from './slices/workflow';
import projectsReducer from './slices/projects';
import projectReducer from './slices/project';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  sentimentAnalysis: sentimentAnalysisReducer,
  keywordExtraction: keywordExtractionReducer,
  mlModels: mlModelsReducer,
  workflows: workflowsReducer,
  workflow: workflowReducer,
  projects: projectsReducer,
  project: projectReducer,
});

export { rootPersistConfig, rootReducer };
