import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
import { getWorkflows } from './workflows';

export const BATCH_PROCESS_STAGES = {
  TEMPLATE: 'TEMPLATE',
  MODEL_SELECTION: 'MODEL_SELECTION',
  UPLOAD: 'UPLOAD',
  SELECT: 'SELECT',
  NAME: 'NAME',
  PROCESS: 'PROCESS',
  DONE: 'DONE',
};

const initialState = {
  // data
  data: null,
  // loading
  loading: false,
  // error
  error: null,
  name: '',
  // batch process stage
  batchProcessStage: BATCH_PROCESS_STAGES.TEMPLATE,
  // uploaded data
  uploadedData: null,
  // selected column for classification
  selectedColumn: null,
  //   keyword model name
  keywordModelName: null,
  // sentiment model name
  sentimentModelName: null,
  // workflow id
  workflowId: null,
  // template
  template: null,
  // filter
  filter: null,
  // remove stop words
  removeStopWords: true,
  // remove punctuation
  removePunctuation: true,
  // lower case
  lowerCase: true,
};

const slice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
      state.data = null;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },

    // Profile Update Success
    getDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },

    // Reset Data
    resetData(state) {
      state.data = null;
      state.loading = false;
      state.error = null;
      state.name = '';
      state.batchProcessStage = BATCH_PROCESS_STAGES.TEMPLATE;
      state.uploadedData = null;
      state.selectedColumn = null;
      state.keywordModelName = null;
      state.sentimentModelName = null;
      state.workflowId = null;
      state.template = null;
      state.filter = null;
    },

    // set input text
    setName(state, action) {
      state.name = action.payload;
    },

    // set batch process stage
    setBatchProcessStage(state, action) {
      state.batchProcessStage = action.payload;
    },

    // set uploaded data
    setUploadedData(state, action) {
      state.uploadedData = action.payload;
    },

    // set selected column
    setSelectedColumn(state, action) {
      state.selectedColumn = action.payload;
    },

    // set keyword model name
    setKeywordModelName(state, action) {
      state.keywordModelName = action.payload;
    },

    // set sentiment model name
    setSentimentModelName(state, action) {
      state.sentimentModelName = action.payload;
    },

    // set workflow id
    setWorkflowId(state, action) {
      state.workflowId = action.payload;
    },

    // set template
    setTemplate(state, action) {
      state.template = action.payload;
    },

    // set filter
    setFilter(state, action) {
      state.filter = action.payload;
    },

    // set remove stop words
    setRemoveStopWords(state, action) {
      state.removeStopWords = action.payload;
    },

    // set remove punctuation
    setRemovePunctuation(state, action) {
      state.removePunctuation = action.payload;
    },

    // set lower case
    setLowerCase(state, action) {
      state.lowerCase = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export function saveKeywordSentimentWorkflow() {
  return async (dispatch, state) => {
    const {
      name,
      keywordModelName,
      sentimentModelName,
      uploadedData: { parsedData },
      selectedColumn,
      removeStopWords,
      removePunctuation,
      lowerCase,
    } = state().workflow;
    dispatch(actions.startLoading());
    try {
      const response = await axios.post(
        `/api/v1/workflows/keyword-sentiment/`,
        {
          texts: parsedData.map((item) => item[selectedColumn]),
          keyword_model_name: keywordModelName,
          sentiment_model_name: sentimentModelName,
          workflow_name: name,
          remove_stopwords: removeStopWords,
          remove_punct_num: removePunctuation,
          lowercase: lowerCase,
        }
      );
      dispatch(actions.getDataSuccess(response.data));
      dispatch(getWorkflows(true));
    } catch (error) {
      console.log(error);
      dispatch(actions.hasError(error));
    }
  };
}

export function getWorkflow(
  workflowId,
  overRiddenData = null,
  filter = null,
  force = false
) {
  return async (dispatch, state) => {
    const {
      workflowId: currentWorkflowId,
      loading,
      removePunctuation,
      removeStopWords,
      lowerCase,
    } = state().workflow;

    if ((currentWorkflowId === workflowId || loading) && !force) {
      return;
    }

    dispatch(actions.startLoading());
    dispatch(actions.setWorkflowId(workflowId));
    try {
      const response = await axios.post(`/api/v1/workflows/${workflowId}/`, {
        overridden_data: overRiddenData,
        filter,
        remove_punct_num: removePunctuation,
        remove_stopwords: removeStopWords,
        lowercase: lowerCase,
      });
      dispatch(actions.getDataSuccess(response.data));
      dispatch(actions.setBatchProcessStage(BATCH_PROCESS_STAGES.DONE));
    } catch (error) {
      dispatch(actions.hasError(error));
    }
  };
}
